import { FC, ReactNode, Suspense, lazy, useEffect } from 'react';

import { useErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { OpenAPI } from '../services';

import { Pages, UnauthenticatedPages } from '../domain/Pages';

import { ApiErrorShortened } from '../util/error-handling';

import { useFeatureFlags } from '../hooks/useFeatureFlags';

import Spinner from '../components/Spinner/Spinner';

import { AuthenticatedRoute } from './AuthenticatedRoute';
import { Callback } from './Callback/Callback';
import { ErrorPage } from './ErrorPage/ErrorPage';
import GenericPage from './GenericPage/GenericPage';
import { LoggedOutPage } from './LoggedOutPage/LoggedOutPage';
import { LogoutPage } from './LogoutPage/LogoutPage';
import { MediaHandler } from './Media/MediaHandler';
import RedirectToUrl from './RedirectToUrl/RedirectToUrl';

const ArticleStream = lazy(() => import('./ArticleStream/ArticleStream'));
const PlatformTagsArticleStream = lazy(
    () => import('./PlatformTagsArticleStream/PlatformTagsArticleStream')
);
const FullArticle = lazy(() => import('./FullArticle/FullArticle'));
const MyCru = lazy(() => import('./MyCru/MyCru'));
const Help = lazy(() => import('./Help/Help'));
const Downloads = lazy(() => import('./Downloads/Downloads'));
const DownloadStream = lazy(() => import('./DownloadStream/DownloadStream'));
const SearchList = lazy(() => import('./SearchList/SearchList'));
const Prices = lazy(() => import('./Prices/Prices'));
const PriceDetailPage = lazy(() => import('./PriceDetailPage/PriceDetailPage'));
const ChatAiPage = lazy(() => import('./ChatAiPage/ChatAiPage'));

const Notifications = lazy(() => import('./Notifications/Notifications'));
const Account = lazy(() => import('./Account/Account'));

const pageNotFoundError: ApiErrorShortened = {
    status: 404,
};

type ConditionalPageProps = {
    shouldRender?: boolean;
    children?: ReactNode;
};

const ConditionalPage: FC<ConditionalPageProps> = ({
    shouldRender = true,
    children,
}) => {
    const { showBoundary } = useErrorBoundary();
    useEffect(() => {
        if (!shouldRender) {
            showBoundary(pageNotFoundError);
        }
    }, [shouldRender]);

    return <>{children}</>;
};

const Router: FC = () => {
    const { pathname, search } = useLocation();
    const { chatAiEnabled } = useFeatureFlags();
    const validGenericPageUrl = Object.values(Pages).includes(
        pathname as Pages
    );

    useEffect(() => {
        const pageUrl = pathname + search;

        OpenAPI.HEADERS = {
            ...OpenAPI.HEADERS,
            ...{ 'X-CRU-Referer': pageUrl },
        };
    }, [pathname, search]);

    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                {/* Authenticated routes */}
                <Route path="/" element={<AuthenticatedRoute />}>
                    <Route
                        path={'/'}
                        element={<Navigate to={Pages.analysis} replace />}
                    />

                    <Route
                        path={Pages.login}
                        element={<Navigate to={Pages.analysis} replace />}
                    />

                    <Route path={'/media/*'} element={<MediaHandler />} />

                    <Route path={Pages.analysis} element={<ArticleStream />} />

                    <Route
                        path={`${Pages.analysis}/:type/:concept/:slug`}
                        element={<PlatformTagsArticleStream />}
                    />

                    <Route
                        path={`${Pages.analysisArticle}/*`}
                        element={<FullArticle />}
                    />

                    <Route
                        path={`${Pages.analysisArticle}/:articleName/preview`}
                        element={<FullArticle isPreview={true} />}
                    />

                    <Route path={Pages.account} element={<Account />} />

                    <Route path={Pages.mycru} element={<MyCru />} />

                    <Route path={Pages.search} element={<SearchList />} />

                    <Route path={Pages.help} element={<Help />} />

                    <Route path={Pages.downloads} element={<Downloads />} />

                    <Route
                        path={`${Pages.downloads}/:productType/:productName`}
                        element={<DownloadStream />}
                    />

                    <Route path={Pages.pricesBundle} element={<Prices />} />

                    <Route
                        path={`${Pages.priceDetail}/:priceId`}
                        element={<PriceDetailPage />}
                    />

                    <Route
                        path={Pages.chatAi}
                        element={
                            <ConditionalPage shouldRender={chatAiEnabled}>
                                <ChatAiPage />
                            </ConditionalPage>
                        }
                    />

                    {/* ST: 16/12/24 - Removed pending product map ratification discussions */}
                    {/* <Route path={Pages.ourProducts} element={<OurProducts />} /> */}

                    <Route
                        path={Pages.notifications}
                        element={<Notifications />}
                    />

                    <Route
                        path={`${Pages.notificationsArticle}/*`}
                        element={<FullArticle hideBookmark isUnlinked />}
                    />

                    <Route
                        path={`${Pages.notificationsArticle}/:articleName/preview`}
                        element={
                            <FullArticle
                                hideBookmark
                                isPreview={true}
                                isUnlinked
                            />
                        }
                    />

                    <Route
                        path={'/:pageName'}
                        element={
                            <ConditionalPage shouldRender={validGenericPageUrl}>
                                <GenericPage />
                            </ConditionalPage>
                        }
                    />

                    <Route
                        path={'/link/:urlPart'}
                        element={<RedirectToUrl />}
                    />

                    <Route
                        path="*"
                        element={<ErrorPage error={pageNotFoundError} />}
                    />
                </Route>

                {/* Unauthenticated routes */}

                <Route
                    path={UnauthenticatedPages.logout}
                    element={<LogoutPage />}
                />

                <Route
                    path={UnauthenticatedPages.loggedOut}
                    element={<LoggedOutPage />}
                />

                <Route
                    path={UnauthenticatedPages.authCallback}
                    element={<Callback />}
                />
            </Routes>
        </Suspense>
    );
};

export default Router;
